import './App.css';
import { createElement, useEffect, useRef, useState } from 'react';
import tt from '@tomtom-international/web-sdk-maps';
import Sidebar from './components/Sidebar';
import Popup from './components/Popup';
import ReactDomServer from'react-dom/server'
import CameraPopup from './components/CameraPopup';

var iconsMapping = {
  '1': {
    class: 'accident', 
    label: 'Accident'
  },
  '2': {
    class: 'fog', 
    label: 'Fog'
  },
  '3': {
    class: 'danger', 
    label: 'Danger'
  },
  '4': {
    class: 'rain', 
    label: 'Rain'
  },
  '5': {
    class: 'ice', 
    label: 'Ice'
  },
  '6': {
    class: 'incident', 
    label: 'Jam'
  },
  '7': {
    class: 'laneclosed', 
    label: 'Lane Closed'
  },
  '8': {
    class: 'roadclosed', 
    label: 'Road Closed'
  },
  '9': {
    class: 'roadworks', 
    label: 'Road Works'
  },
  '10': {
    class: 'wind', 
    label: 'Wind'
  },
  '11': {
    class: 'flooding', 
    label: 'Flooding'
  },
  '14': {
    class: 'brokendownvehicle', 
    label: 'Broken Down Vehicle'
  }, 
  '0': {
    class: 'unknown',
    label: 'Unknown' 
  }
};
var incidentSeverity = {
  '0': 'unknown',
  '1': 'minor',
  '2': 'moderate',
  '3': 'major',
  '4': 'undefined'
};
var viewOffset = {
  'North': [0, -25], 
  'South': [0, 55], 
  'East': [40, 15], 
  'West': [-40, 15], 
  'undefined 1': [-40, -25], 
  'undefined 2': [-40, 55], 
  'undefined 3': [40, -25], 
  'undefined 4': [40, 55], 
}

function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

let apiKey = 'XUPIXLylIXx9aKkOqIW5BG9tnWPfBJg7'

function App() {
  let [map, setMap] = useState(null)
  let [incidents, setIncidents] = useState(null)
  let [cameras, setCameras] = useState(null)
  let [controls, setControls] = useState({
    trafficFlow: true, 
    trafficIncidents: false, 
    trafficCameras: true
  })
  let [points, setPoints] = useState(null)
  let [incidentFilter, setincidentFilter] = useState(['1','3','6','7','8','9','14'])
  let [severityFilter, setSeverityFilter] = useState(['2','3'])
  let markersRef = useRef([])
  let camerasRef = useRef([])
  let tempCamerasRef = useRef([])
  let cameraEventsAdded = false
  let pointSourceRef = useRef(null)
  let mapElement = useRef()

  useEffect(()=> {
    tt.setProductInfo("Touchscreen App", "0.0.1")
    let mapObj = tt.map({
      key: apiKey,
      container: "map", 
      center: [-123.03450521031469, 49.148433252644075], 
      zoom: 10, 
      style: {
        map: '2/basic_street-dark', 
        trafficFlow: '2/flow_relative-dark', 
        trafficIncidents: '2/incidents_dark'
      },
      stylesVisibility: {
        trafficFlow: controls.trafficFlow, 
        trafficIncidents: controls.trafficIncidents
      }
    })
    
    
    mapObj.addControl(new tt.NavigationControl({showCompass: false}))
      fetch('/vizzion/data')
        .then(res=>res.json())
        .then(json=>{
          let cameraData = []
          json.cameraData.map(camera=>{
            cameraData.push({coordinates: [camera.long, camera.lat], properties: camera})
          })
          setCameras(cameraData)})
        .catch(e=>console.log(e))
      fetch('/api/incidents')
        .then(res=>res.json())
        .then(json=>setIncidents(json))
        .catch(e=>console.log(e))

    setMap(mapObj)

    return () => {
      mapObj.remove()
    }
  }, [])

  useEffect(()=>{

    if (map) {
      map.on('load', ()=> {
        addClusters(points, controls.trafficIncidents)
      })
      addClusters(points, controls.trafficIncidents)
    }
  },[points])

  useEffect(()=>{
    if (map) {
      map.on('load', ()=> {
        addCameraClusters(cameras, controls.trafficCameras)
      })
      addCameraClusters(cameras, controls.trafficCameras)
    }
  }, [cameras])

  useEffect(()=> {
    try {

      if (map) {
        controls.trafficFlow ? map.showTrafficFlow() : map.hideTrafficFlow()
        controls.trafficIncidents?map.showTrafficIncidents(): map.hideTrafficIncidents();
        map.getLayer('clusters') && map.removeLayer('clusters')
        map.getLayer('cluster-count') && map.removeLayer('cluster-count')
        map.getSource('point-source') && map.removeSource('point-source')

        if (!controls.trafficIncidents) {
          addClusters(points, controls.trafficIncidents)
          map.getLayer('clusters') && map.removeLayer('clusters')
          map.getLayer('cluster-count') && map.removeLayer('cluster-count')
        } else {
          addClusters(points, controls.trafficIncidents)
        }
        if (!controls.trafficCameras) {
          addCameraClusters(cameras, controls.trafficCameras)
          map.getLayer('cameraclusters') && map.removeLayer('cameraclusters')
          map.getLayer('cameracluster-count') && map.removeLayer('cameracluster-count')
          map.getSource('camera-source') && map.removeSource('camera-source')
        } else {
          addCameraClusters(cameras, controls.trafficCameras)
        }
      }
    } catch (e) {
      console.log('error controls', e)
    }

  }, [controls, incidentFilter, severityFilter])


useEffect(()=> {
  if (incidents) {
    let pointsArray = []
    incidents.incidents.map((el, i)=>{
      let icon = iconsMapping[el.properties.iconCategory].class
      let position = el.geometry.coordinates[0]
      let pointObj1 = {
        coordinates: el.geometry.coordinates[0], 
        properties: {
          ...el.properties,
          state: 'start', 
          name: `Point ${i}-start`
        }
      }
      let pointObj2 = {
        coordinates: el.geometry.coordinates[el.geometry.coordinates.length-1], 
        properties: {
          ...el.properties,
          state: 'end', 
          name: `Point ${i}-end`, 
        }
      }
        pointsArray.push(pointObj1)
        // pointsArray.push(pointObj2)

    })
    // addClusters(points, controls.trafficIncidents)
    setPoints(pointsArray)
  }

}, [incidents])


  function createMarker(icon, position, color) {
    var markerElement = document.createElement('div');
    markerElement.className = 'marker';
    var markerContentElement = document.createElement('div');
    markerContentElement.className = 'marker-content';
    markerContentElement.style.backgroundColor = 'black';
    markerElement.appendChild(markerContentElement);
    var iconElement = document.createElement('div');
    iconElement.className = 'icon-container'
    iconElement.innerHTML = '<div class="tt-traffic-icon -details">' +
                              '<div class="tt-icon-circle-' + incidentSeverity[color] + ' -small">' +
                                  '<div class="tt-icon-' + iconsMapping[icon].class + '"></div>' +
                              '</div>' +
                          '</div>' ;
    // iconElement.className = 'tt-icon-roadclosed';
    // iconElement.style.backgroundImage =
    //     'url(https://api.tomtom.com/maps-sdk-for-web/cdn/static/' + 'icon' + ')';
    markerContentElement.appendChild(iconElement);

    return new tt.Marker({element: markerElement, anchor: 'bottom'}).setLngLat(position)
}

function createCameraMarker() {
    var markerElement = document.createElement('div');
    markerElement.className = 'camera';

    var markerContentElement = document.createElement('div');
    markerContentElement.className = 'camera-content';
    markerElement.appendChild(markerContentElement);

    var iconElement = document.createElement('div');
    iconElement.className = 'camera-icon';
    iconElement.style.backgroundImage =
        'url("/cameraicon.png")';
    markerContentElement.appendChild(iconElement);
    return markerElement
}

const addCameraClusters = (camerasArray, showIncidents = controls.trafficCameras) => {
  try {
    if (!camerasArray || !map) {
      return;
    }
    var geoJson = {
      type: 'FeatureCollection', 
      features: camerasArray.map(function(point){
                return {
                  type: 'Feature', 
                  geometry: {
                    type: 'Point', 
                    coordinates: point.coordinates
                  }, 
                  properties: point.properties
                }
              })
    }
    function refreshMarkers() {
      Object.keys(camerasRef.current).forEach(function(id) {
        camerasRef.current[id].remove();
        delete camerasRef.current[id];
      });
      Object.keys(tempCamerasRef.current).forEach(function(id){
        tempCamerasRef.current[id].remove();
        delete tempCamerasRef.current[id];
      })
      if (showIncidents) {
        map.querySourceFeatures('camera-source').forEach(function(feature) {
            if (feature.properties && !feature.properties.cluster) {
                var id = parseInt(feature.properties.id, 10);
                if (!camerasRef.current[id]) {
                  var markerElement = createCameraMarker()
                    // markerElement.addEventListener('click', (e)=>{
                    //   e.preventDefault()
                    //   map.easeTo({
                    //     center: feature.geometry.coordinates
                    //   })
                    // })  
                  var newMarker = new tt.Marker({element: markerElement})
                                    .setLngLat(feature.geometry.coordinates)
                    
                    newMarker.addTo(map);
                    newMarker.setPopup(new tt.Popup({closeOnMove: false, offset: 30})
                            .setHTML(ReactDomServer.renderToString(
                              <CameraPopup data={feature.properties}/>))
                              .on('open', ()=>{

                                let imagediv = document.getElementById(`imageDiv-${feature.properties.id}`)
                                imagediv.style.display = 'block'
                                
                              })
                              );
                    camerasRef.current[id] = newMarker;

                }
            }
        });
      }
} 
    if (showIncidents) {
    if (!map.getSource('camera-source')) {
      map.addSource('camera-source', {
        type: 'geojson', 
        data: geoJson,
        cluster: true, 
        // clusterMaxZoom: 12,
        clusterRadius: 75
      })
      
      map.addLayer({
        id: 'cameraclusters', 
        type: 'circle', 
        source: 'camera-source',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#fff',
          'circle-radius': [
              'step',
              ['get', 'point_count'],
              12,
              4,
              15,
              7,
              18
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': 'black',
          'circle-stroke-opacity': 1
      }
      })
      map.addLayer({
        id: 'cameracluster-count',
        type: 'symbol',
        source: 'camera-source',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 16
        },
        paint: {
            'text-color': 'black'
        }
    });
    }
    }
    refreshMarkers()
    map.on('data', function(e) {
      if (e.sourceId !== 'camera-source' || !map.getSource('camera-source').loaded()) {
          return;
      }
      refreshMarkers();
      if (!cameraEventsAdded) {
          map.on('move', refreshMarkers);
          map.on('moveend', refreshMarkers);
          cameraEventsAdded = true;
      }
  });

    map.on('click', 'cameraclusters', async function(e) {
      Object.keys(tempCamerasRef.current).forEach(function(id) {
        tempCamerasRef.current[id].remove();
        delete camerasRef.current[id];
    });
      var features = map.queryRenderedFeatures(e.point, { layers: ['cameraclusters'] });
      var clusterId = features[0].properties.cluster_id;
      let clusterSource = map.getSource('camera-source')
      // let newId = null
      let getCameras = new Promise(function(resolve) {
        clusterSource.getClusterLeaves(clusterId, 10, 0,(err, leaf)=>{
          if (leaf.length > 4) {
            resolve(false)
          }
          function deg2rad(deg) {
            return deg * (Math.PI/180)
          }
          function getDistance(lat1,lon1, lat2,lon2) {
            var R = 6371
            var dLat = deg2rad(lat2-lat1)
            var dLon = deg2rad(lon2-lon1)
            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2)
            ; 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            var d = R * c; // Distance in km
            return d;
          }
          
          let distance = leaf.reduce((total, item)=>{
            let lat1 = total.item ? total.item.geometry.coordinates[1] : total.geometry.coordinates[1]
            let lon1 = total.item ? total.item.geometry.coordinates[0] : total.geometry.coordinates[0]
            let lat2 = item.geometry.coordinates[1]
            let lon2 = item.geometry.coordinates[0]
            let distance = getDistance(lat1, lon1, lat2, lon2)
            return {item:item , distance: total.item ? total.distance + distance : distance, list: total.list?[...total.list, item]: [total, item]}
          })
          resolve(distance)
        })
      })
      let clusterCameras = await getCameras
      if (!Boolean(clusterCameras)) {
        clusterSource.getClusterChildren(clusterId, (features)=>console.log('here',features))
        clusterSource.getClusterExpansionZoom(clusterId, function(err, zoom) {
          if (err) {
              return;
          }
          map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: Math.max(zoom+0.5, 14)
          });
      });
      } 
      else {
        let lnglat =features[0].geometry.coordinates
        if (map.getZoom() < 14) {
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: Math.max(14)
          });
        }
        let list = ['North', 'East', 'South', 'West', 'undefined', 'undefined 2', 'undefined 3', 'undefined 4']
        clusterCameras.list.map(camera=>{
          let cameraView = camera.properties.view
         if (!cameraView) {
            cameraView = list[list.length-1]
          } 
          if (list.includes(cameraView)) {
            list = list.filter(el=>el!==cameraView)
          } else {
            cameraView = list[list.length-1]
          }
          let popup = new tt.Popup({offset: [viewOffset[cameraView][0], viewOffset[cameraView][1] -30 ]})
            .setHTML(ReactDomServer.renderToString(
              <CameraPopup data={camera.properties}/>))
              .on('open', ()=>{
                let imagediv = document.getElementById(`imageDiv-${camera.properties.id}`)
                imagediv.style.display = 'block'
              });
            
          let newMarker = new tt.Marker({element: createCameraMarker()})
            .setLngLat(lnglat)
            .setOffset(viewOffset[cameraView])
            .setPopup(popup)
            .addTo(map)
          tempCamerasRef.current.push(newMarker)
        })
        clusterSource.getClusterExpansionZoom(clusterId, function(err, zoom) {
          if (err) {
              return;
          }

      });
      }
  });
  
  map.on('mouseenter', 'cameraclusters', function() {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', 'cameraclusters', function() {
      map.getCanvas().style.cursor = '';
  }); 
  // map.on('click', function(e){
  //   var markerFeature = map.queryRenderedFeatures(e.point);
  //   console.log(markerFeature)
  // })

  } catch (e) {
    console.log('error in cameras', e)
  }
}

const addClusters = (pointsArray, showIncidents = controls.trafficIncidents) => {
  try {
    if (!pointsArray ||!map) {
      return;
    }
  var markersOnTheMap = {};
  var eventListenersAdded = false;
  
  var geoJson = {
      type: 'FeatureCollection',
      features: pointsArray
        .filter(e=>severityFilter.includes(e.properties.magnitudeOfDelay.toString()))
        .filter(e=>incidentFilter.includes(e.properties.iconCategory.toString()))
        .map(function(point) {
          return {
              type: 'Feature',
              geometry: {
                  type: 'Point',
                  coordinates: point.coordinates
              },
              properties: point.properties
          };
      })
  };
  function refreshMarkers() {
      
      Object.keys(markersRef.current).forEach(function(id){
        markersRef.current[id].remove();
        delete markersRef.current[id];
      })
      let markerArray = []
      if (showIncidents) {
      map.querySourceFeatures('point-source').forEach(function(feature, i) {
          if (feature.properties && !feature.properties.cluster) {
            // var id = parseInt(feature.properties.id, 10);
              var id = i
              if (!markersRef.current[id]) {
                if (incidentFilter.includes(feature.properties.iconCategory.toString()) && severityFilter.includes(feature.properties.magnitudeOfDelay.toString())) {

                  var newMarker = createMarker(feature.properties.iconCategory, feature.geometry.coordinates, feature.properties.magnitudeOfDelay)
                  newMarker.setPopup(new tt.Popup({offset: 30}).setHTML(ReactDomServer.renderToString(
                    <Popup data={feature.properties} eventsString={feature.properties.events} category={iconsMapping[feature.properties.iconCategory].label} severity={incidentSeverity[feature.properties.magnitudeOfDelay]}/>)));
                  newMarker.addTo(map);
                  markersRef.current[id] = newMarker;
                  markerArray.push(newMarker)
              }
          }}
      });
    }
  }
    if (showIncidents) {
      if (!map.getSource('point-source')) {
        map.addSource('point-source', {
          type: 'geojson',
          data: geoJson,
          cluster: true,
          clusterMaxZoom: 11,
          clusterRadius: 50
      });


    map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'point-source',
        filter: ['has', 'point_count'],
        paint: {
            'circle-color': [
                'step',
                ['get', 'point_count'],
                // '#EC619F',
                '#004B7F',
                4,
                '#004B7F',
                // '#008D8D',
                7,
                '#004B7F',
                // '#004B7F'
            ],
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                15,
                4,
                20,
                7,
                25
            ],
            'circle-stroke-width': 1,
            'circle-stroke-color': 'white',
            'circle-stroke-opacity': 1
        }
    });

      map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'point-source',
          filter: ['has', 'point_count'],
          layout: {
              'text-field': '{point_count_abbreviated}',
              'text-size': 16
          },
          paint: {
              'text-color': 'white'
          }
      });
    }
  }

    refreshMarkers()
    map.on('data', function(e) {
        if (e.sourceId !== 'point-source' || !map.getSource('point-source')) {
            return;
        }
        refreshMarkers();

        if (!eventListenersAdded) {
            map.on('move', ()=>refreshMarkers());
            map.on('moveend', ()=>refreshMarkers());
            eventListenersAdded = true;
        }
    });

    map.on('click', 'clusters', function(e) {
        var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        var clusterId = features[0].properties.cluster_id;
        map.getSource('point-source').getClusterExpansionZoom(clusterId, function(err, zoom) {
            if (err) {
                return;
            }
            map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom + 0.5
            });
        });
    });

    map.on('mouseenter', 'clusters', function() {
        map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'clusters', function() {
        map.getCanvas().style.cursor = '';
    });
  } catch (e) {
    console.log('error clusters', e)
  }

}


const handleLayersChange = (e) => {
  setControls(prevState=>({
    ...prevState, 
    [e.target.name]: e.target.checked
  }))
}

const handleIncidentChange = (e) => {
  if (incidentFilter.includes(e.target.value)) {
    setincidentFilter(incidentFilter.filter(a=>a!==e.target.value.toString()))
  } else {
    setincidentFilter([...incidentFilter, e.target.value.toString()])
  }

}
const handleSeverityChange = (e) => {
  if (severityFilter.includes(e.target.value)) {
    if (e.target.value === '0') {
      setSeverityFilter(severityFilter.filter(a=>a!=='0' && a!=='4'))
    } else {
      setSeverityFilter(severityFilter.filter(a=>a!==e.target.value.toString()))
    }
  } else {
    if (e.target.value === '0') {
      setSeverityFilter([...severityFilter, '0', '4'])
    } else {
      setSeverityFilter([...severityFilter, e.target.value.toString()])
    }
  }

}
  return (
    <div className="flex h-full w-full overflow-hidden">
      <div className="h-full flex-grow">
        <div id="map" ref={mapElement} className='h-full' />
      </div>
      <div className="w-[350px] bg-sb text-white font-bold py-2 flex justify-center">
        <Sidebar 
          controls={controls} 
          handleLayersChange={handleLayersChange}
          severity={incidentSeverity}
          categories={iconsMapping}
          incidentFilter={incidentFilter}
          handleIncidentChange={handleIncidentChange}
          severityFilter={severityFilter}
          handleSeverityChange={handleSeverityChange}
          />
      </div>
    </div>
  );
}

export default App;
