import React from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Switch} from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function Sidebar({controls, handleLayersChange, categories, severity, handleIncidentChange, incidentFilter, severityFilter, handleSeverityChange}) {
    
    return (
        <div className='flex flex-col relative w-full h-screen overflow-hidden'>
            <div className='p-2 text-xl'>Layer Control</div>
            <div className='flex-grow w-full bg-sb overflow-auto'>
                <Accordion className='bg-sb text-white'>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon className='text-white'/>}
                        id="layers-header"
                    >Toggle Layers</AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                        <FormControlLabel control={<Switch onChange={handleLayersChange} name={'trafficFlow'} checked={controls.trafficFlow} />} label="Traffic Flow" />
                        <FormControlLabel control={<Switch onChange={handleLayersChange} name={'trafficIncidents'} checked={controls.trafficIncidents} />} label="Traffic Incidents" />
                        <FormControlLabel control={<Switch onChange={handleLayersChange} name={'trafficCameras'} checked={controls.trafficCameras} />} label="Traffic Cameras" />
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='bg-sb text-white'>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon className='text-white'/>}
                        id="incidents-header"

                    >Filter Incidents</AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            {Object.keys(categories).map(key=>{
                                return <FormControlLabel 
                                    key={key} 
                                    control={<Checkbox 
                                                onChange={handleIncidentChange} 
                                                checked={incidentFilter.includes(key)} 
                                                value={key}
                                                />} label={categories[key].label}/>
                            })}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='bg-sb text-white'>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon className='text-white'/>}
                        id="severity-header"
                    >
                        Filter Severity
                    </AccordionSummary>
                    <AccordionDetails className="flex flex-col">
                        <>
                        {Object.keys(severity).map(key=>{
                            console.log(typeof key)
                            if (key !== '0' && key !== '4') {
                                return <FormControlLabel
                                className="capitalize"
                                key={key}
                                control={<Checkbox 
                                    onChange={handleSeverityChange}
                                    checked={severityFilter.includes(key)}
                                    value={key}
                                />}
                                label={severity[key]}
                            />
                            }
                        })}
                        <FormControlLabel 
                            control={<Checkbox 
                                onChange={handleSeverityChange}
                                checked={severityFilter.includes('0')}
                                value={'0'}
                            />}
                            label="Unknown"
                        />
                        </>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}
