import React from 'react'

export default function CameraPopup({data, test, setRef}) {
    const handleImageLoad = () => {
        console.log("load")
        document.getElementById('imageDiv').style.display = ('inherit')
        document.getElementById('imageDiv').children[0].style.height = 300

    }
  return (
    <div className=''>
        <div >
            <div id={`imageDiv-${data.id}`} style={{display: 'none'}}>
                <img style={{maxWidth: 'auto', maxHeight:'300px'}} alt={"Camera Footage"} onError={e=>{
                    console.log('image err')
                    e.target.onError = null;
                    e.target.src = '/vizzion/NoImage'
                }} onLoad={handleImageLoad} src={`/vizzion/image/${data.id}`}/>
            </div>
            {/* <div>Test</div> */}
            <div><strong>Name: </strong>{data.name}</div>
            <div><strong>ID: </strong>{data.id}</div>
        </div>
    </div>
  )
}
