import React from 'react'

export default function Popup({data, eventsString, category, severity}) {
  let description = JSON.parse(eventsString)[0].description
  return (
    <div className='flex flex-col gap-2'>
        <div className='text-xl font-semibold'>{category} - {severity}</div>
        <hr className=" border-gray-600"/>
        <div className="text-lg ">{description}</div>
        <hr className=" border-gray-600"/>

        {data.from !== data.to ?
          <div className="tex-lg">
            <div><strong>From: </strong>{data.from}</div>
            <div><strong>To: </strong>{data.to}</div>
          </div>
          :
          <div><strong>Location: </strong>{data.to}</div>
        }
      
    </div>
  )
}
